import React from 'react'
import GoogleMapReact from 'google-map-react'

const GoogleMark = () => {
  const greatPlaceStyle = {
    position: 'absolute',
    top: '10%',
    transform: 'translate(-50%, -70%)',
  }
  return (
    <div style={greatPlaceStyle}>
      <img width="64" height={64} src="/icons/map-pin.svg" alt="pin" />
    </div>
  )
}

const GoogleMap = ({ data }) => {
  const defaultProps = {
    center: {
      lat: data.latitude,
      lng: data.longitude,
    },
    zoom: 13,
  }

  const styles = {
    styles: [
      // Geometry
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            color: '#1F1F1F',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#1A1A1A',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#202020',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: '#65321F',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#993D1A',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#181818',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#1D1D1D',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },

      // Labels
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#4D4D4D',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#DDDDDD',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#CCCCCC',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#CCCCCC',
          },
        ],
      },
    ],
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
      }}
    >
      <GoogleMapReact
        options={styles}
        style={defaultProps.styles}
        bootstrapURLKeys={{ key: 'AIzaSyAkeI2SUhaEZXkA3ZLeFhcp40biynVYxgY' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <GoogleMark
          lat={data.latitude}
          lng={data.longitude}
          text="Kreysesssssr Avrora"
        />
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMap
