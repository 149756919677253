import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import * as style from '../../assets/scss/pages/contacts.module.scss'
import keyGenerator from '../utils/keyGenerator'
import GoogleMap from '../components/Pages/Contacts/GoogleMap'
import FormSection from '../components/reusable/Form/FormSection'

const Contacts = ({ data, location }) => {
  const contacts = [
    data.wp.acfOptionsContacts.contacts.email,
    data.wp.acfOptionsContacts.contacts.phone,
    data.wp.acfOptionsContacts.contacts.address,
  ]

  const seoData = data.wpPage.metaTags
  return (
    <MainLayout seoData={seoData} location={location}>
      <PageMainSection
        image="contacts.png"
        imageMobile="contacts_mobile.png"
        content
        newClass={style.contacts}
        title={data.wpPage.page_contacts.contactsHeader.title}
        description={data.wpPage.page_contacts.contactsHeader.description}
      >
        <div className={style.contactsWrap}>
          <div className="container">
            {contacts.map((elem, index) => (
              <div
                className={style.contactsItem}
                key={keyGenerator(index, elem)}
              >
                <h2 className={style.contactsItemName}>{elem.title}:</h2>
                <p className={style.contactsItemData}>{elem.description}</p>
              </div>
            ))}
          </div>
        </div>
      </PageMainSection>
      <FormSection showTitle={false} />
      <section className={style.map}>
        <GoogleMap data={data.wpPage.page_contacts.map} />
      </section>
    </MainLayout>
  )
}
export const query = graphql`
  {
    wpPage(databaseId: { eq: 50 }) {
      metaTags {
        seoTitle
        seoHiddenTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_contacts {
        map {
          city
          country
          countryShort
          latitude
          longitude
          placeId
          postCode
          state
          stateShort
          streetAddress
          streetName
          streetNumber
          zoom
        }
        contactsHeader {
          description
          title
        }
      }
    }
    wp {
      acfOptionsContacts {
        contacts {
          email {
            description
            fieldGroupName
            title
          }
          address {
            description
            fieldGroupName
            title
          }
          phone {
            description
            fieldGroupName
            title
          }
        }
      }
    }
  }
`

Contacts.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Contacts
